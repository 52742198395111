import React from "react";
import { Link } from "gatsby";
import styles from "./styles.module.css";
import auto_products from "../../../../assets/img/auto_products.gif";
import getweb_lboard2 from "../../../../assets/img/getweb_lboard2.gif";
import auto_web_templates from "../../../../assets/img/auto_web_templates.gif";
import BNLGO93F from "../../../../assets/img/BNLGO93F.gif";
import EMFCB86F from "../../../../assets/img/EMFCB86F.gif";
import EMCQ191F from "../../../../assets/img/EMCQ191F.jpg";
import Button from "../../components/Button";

export default function Automobile() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Secteur de l'automobile</h1>
        <p>
          Deluxe offre une gamme étendue de produits conçus pour les artisans du
          secteur de l’automobile : commandes de service, factures pour
          distributrices, étiquettes d’entretien, porte-clés, protège-tapis,
          chèques d’affaires, de même qu’un éventail complet d’imprimés
          promotionnels et d’articles publicitaires tels que cartes postales,
          cartes de fidélité, blocs-notes, t-shirts, stylos et tasses qui feront
          en sorte que vos clients capteront votre message à coup sûr!
        </p>
      </div>
      <div className={styles.row}>
        <h2>Des produits conçus pour contribuer au secteur de l’automobile</h2>
        <Link to="/votre-entreprise/automobile/produits-pour-lautomobile/">
          <img src={auto_products} alt="Produits automobiles"></img>
        </Link>
        <Button
          text={"Produits automobiles D'Info"}
          url="/votre-entreprise/automobile/produits-pour-lautomobile/"
        />
      </div>
      <div className={styles.row}>
        <Link to="/developpez/services-web/solutions-getweb/">
          <img
            src={getweb_lboard2}
            alt="Besoin de cliquer avec plus clients? GetWeb c'est ici!"
          ></img>
        </Link>

        <Button
          text={"GetWeb D'Info"}
          url="/developpez/services-web/solutions-getweb/"
        />
      </div>
      <div className={styles.row}>
        <h2>Modèles de sites Web pour le secteur de l’automobile</h2>
        <Link to="/votre-entreprise/automobile/modles-de-sites-pour-lautomobile/">
          <img
            src={auto_web_templates}
            alt="Modèles de sites Web pour le secteur de l’automobile"
          ></img>
        </Link>
        <Button
          text={"Modèles de sites Web pour D'Info"}
          url="/votre-entreprise/automobile/modles-de-sites-pour-lautomobile/"
        />
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <Link to="/developpez/services-web/conception-de-sites-web/">
            <img
              src={BNLGO93F}
              alt="Quelle est la première impression de vos client à propos de votre entreprise?"
            ></img>
          </Link>
        </div>
        <div className={styles.column}>
          <Link to="/developpez/articles-couleur/">
            <img
              src={EMFCB86F}
              alt="Rabais de 25% sur votre première commande d'imprimes promotionnels couleur!"
            ></img>
          </Link>
        </div>
        <div className={styles.column}>
          <Link to="/gerez/cheques/">
            <img
              src={EMCQ191F}
              alt="Achetez-vous encore vos chèques de la banque? Visez nos chèques et prenez le virage NEBS!"
            ></img>
          </Link>
        </div>
      </div>
    </div>
  );
}
