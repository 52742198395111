import React from "react";
import Layout from "../../../other/layout";
import Automobile from "../../../components/AC-StaticPages/VotreEntreprise/Subpages/Automobile";
import SEO from "../../../other/seo";

export default function automobile() {
  return (
    <Layout>
      <SEO title="Secteur de l'automobile" />

      <Automobile />
    </Layout>
  );
}
